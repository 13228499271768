export default class FooterComponent {
    constructor() {
        FooterComponent.mapSwitcher()
    }

    static mapSwitcher() {
        const switcher = '[data-element="footer-map-switcher"]'
        const target = $(switcher).find('button, img')
        $(target).on('click', () => {
            $(target).toggleClass('is-active')
        })
    }
}
