import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    isMobile = window.matchMedia('(max-width: 767px)').matches

    constructor() {
        this.sticky()
        this.menu()
        this.burger()
        this.tools()
    }

    sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 140 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
            }
        })
    }

    menu() {
        if (!this.isMobile) {
            //Open/Close menu
            $(NavbarEnum.MENU_ITEM).on('mouseover', () => {
                if (!$(NavbarEnum.BODY).hasClass('menu-hover')) {
                    $(NavbarEnum.BODY).addClass('menu-hover')
                }
            })

            $(NavbarEnum.MENU_ITEM).on('mouseleave', () => {
                if ($(NavbarEnum.BODY).hasClass('menu-hover')) {
                    $(NavbarEnum.BODY).removeClass('menu-hover')
                }
            })
        }
    }

    burger() {
        if (this.isMobile) {
            //Open/Close menu
            $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
                $(NavbarEnum.BODY).toggleClass('menu-open')

                //Hide ecomode
                $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')

                if ($(NavbarEnum.BURGER_SUBMENU).hasClass('submenu-open')) {
                    setTimeout(() => {
                        $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
                    }, 200)
                }
            })

            //Open/Close submenu
            $(NavbarEnum.BURGER_MENU_MORE).on('click', function (e) {
                e.preventDefault()
                $(this).siblings(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
                $('body').addClass('submenu-open')
            })

            $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
                e.preventDefault()
                $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            })
        }
    }

    tools() {
        if (this.isMobile) {
            $('.menu__wrapper').append($(NavbarEnum.CUSTOM_TOOLS))
        }
    }
}
